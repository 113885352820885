import styled from "styled-components"
import { rem, ellipsis } from "polished"

import _Link from "../links/link"

export const Link = styled(_Link)`
  color: ${props => props.theme.color.tags};
`
export const List = styled.ul`
  ${ellipsis()}

  list-style: none;
  margin: 0;
  padding: 0;
  font-family: ${props => props.theme.font.secondary};

  ${Link} {
    font-weight: 300;
    letter-spacing: ${rem("-0.39px")};
  }
`
export const ListItem = styled.li`
  display: inline;

  & + & {
    &::before {
      content: "|";
      padding: 0 ${rem("4px")};
    }
  }
`
