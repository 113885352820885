import React from "react"
import PropTypes from "prop-types"

import {
  Wrapper,
  Card,
  Badge,
  Name,
  Footer,
  SocialLinks,
} from "./team-member-circle-style"

TeamMemberCircle.propTypes = {
  position: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      size: PropTypes.number,
      color: PropTypes.string,
    }).isRequired,
  ),
  size: PropTypes.number,
  cardColor: PropTypes.string,
  iconsSize: PropTypes.number,
  iconsColor: PropTypes.string,
}

export default function TeamMemberCircle({
  position,
  name,
  socialLinks,
  size = 355,
  cardColor = "#fff",
  iconsSize = 28,
  iconsColor = "#fff",
  ...props
}) {
  return (
    <Wrapper {...props} size={size}>
      <Card
        color={cardColor}
        cardBorderSize={20}
        hasLinks={Boolean(socialLinks)}
      >
        <Badge>{position}</Badge>
        <Name>{name}</Name>
      </Card>
      {socialLinks && (
        <Footer>
          <SocialLinks
            color={iconsColor}
            size={iconsSize}
            links={socialLinks}
          />
        </Footer>
      )}
    </Wrapper>
  )
}

export * from "./team-member-circle-style"
