import styled from "styled-components"
import { rem } from "polished"
import { IoIosArrowForward } from "react-icons/io"

import _Tags from "./tags"
import _Link from "../links/link"

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 1.25;
`
export const Icon = styled.img`
  box-sizing: content-box;
  padding-right: ${rem("17px")};
  min-width: ${rem("34px")};
  user-select: none;
`
export const Link = styled(_Link)`
  color: ${props => props.theme.color.category};
`
export const Arrow = styled(IoIosArrowForward)`
  box-sizing: content-box;
  padding: 0 ${rem("3px")};
  min-width: ${rem("16px")};
`
export const Tags = styled(_Tags)``
