import React from "react"
import styled from "styled-components"
import { rem, fluidRange, transparentize } from "polished"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import _Layout, { Main } from "../containers/layout"
import { Link } from "../components/header"
import SEO from "../containers/seo"
import _ContactButton from "../components/buttons/contact"
import _Hero, { ImageWrapper } from "../components/hero"
import Badge from "../components/badge"
import IconCard from "../components/cards/icon-card"
import Card from "../components/cards/card"
import _CaseStudy from "../components/cards/case-study"
import _TeamMember from "../components/cards/team-member"
import TeamMemberCircle from "../components/cards/team-member-circle"
import _IconList, { List, ListItem } from "../components/lists/icon-list"
import NewsletterForm from "../components/forms/newsletter"
import _Clients from "../containers/clients"
import footerImg from "../images/backgrounds/footer.svg"
import _Button from "../components/buttons/button"

export default function HomePage({ data }) {
  const icons = _findByName(data.icons.edges)
  const iconsLight = _findByName(data.iconsLight.edges)
  const backgroundsVector = _findByName(data.backgroundsVector.edges)
  const illustrationsVector = _findByName(data.illustrationsVector.edges)

  return (
    <Layout backgroundImage={backgroundsVector("page").publicURL}>
      <SEO title="Home" />
      <Hero
        title="cometari"
        subtitle={
          <>
            <span>DevOps</span> Special Team
          </>
        }
        text="We are a team of talented and experienced software engineers and scientists specializing in
        software development, DevOps, Log Analysis and TravelTech. We are huge fans of
        designing, developing and maintaining distributed and scalable systems (as well as soft
        internet) for your company."
        image={_getImage(illustrationsVector)(
          "devops-special-team",
          "computer",
        )}
      />
      <Clients />
      <Specializations id="specializations">
        <SectionTitle>specializations</SectionTitle>
        <SectionSubtitle>we are best at</SectionSubtitle>
        <SectionItems>
          <IconList icon={_getImage(iconsLight)("infinity")} title="DevOps">
            <List>
              <ListItem>Automation of the process</ListItem>
              <ListItem>Cloud Native</ListItem>
              <ListItem>Containerization</ListItem>
              <ListItem>Cluster management Kubernetes / Swarm</ListItem>
              <ListItem>CI/CD</ListItem>
            </List>
          </IconList>
          <IconList
            icon={_getImage(iconsLight)("analysis")}
            title="Observability and Log Analysis"
          >
            <List>
              <ListItem>Log Management based on Elastic Stack</ListItem>
              <ListItem>
                Capacity planning and tuning for Elastic Stack
              </ListItem>
              <ListItem>Infrastructure based on Grafana</ListItem>
              <ListItem>Application performance monitoring (APM)</ListItem>
              <ListItem>Containers monitoring</ListItem>
            </List>
          </IconList>
          <IconList
            icon={_getImage(iconsLight)("cloud-computing")}
            title="Cloud Services"
          >
            <List>
              <ListItem>Design and implementation</ListItem>
              <ListItem>Managed services</ListItem>
              <ListItem>Adoption and migration</ListItem>
              <ListItem>Hybrid cloud solutions</ListItem>
              <ListItem>Cost optimization</ListItem>
            </List>
          </IconList>
          <IconList
            icon={_getImage(iconsLight)("airplane")}
            title="Travel Tech"
          >
            <List>
              <ListItem>Sabre Authorized Partner</ListItem>
              <ListItem>Integration of Sabre Web services</ListItem>
              <ListItem>Red App initial design</ListItem>
              <ListItem>Airport Finder</ListItem>
            </List>
          </IconList>
        </SectionItems>
      </Specializations>
      <Strenghts>
        <SectionTitle>strengths</SectionTitle>
        <SectionSubtitle>Why working with us pays off?</SectionSubtitle>
        <SectionItems>
          <SectionCard icon={_getImage(icons)("knowledge")}>
            <CardTitle>Huge experience and extensive knowledge</CardTitle>
            <CardText>
              Our team consists of specialized developers and engineers who,
              together, have years of experience in the IT industry. Therefore,
              we guarantee an individual approach to each project we deal with.
            </CardText>
          </SectionCard>
          <SectionCard icon={_getImage(icons)("destination")}>
            <CardTitle>Reliable IT partner</CardTitle>
            <CardText>
              We work on trusted systems and tools and we work only with
              reliable partners. Cometari has been an Sabre Authorized Developer
              for several years and offers complementary solutions.
            </CardText>
          </SectionCard>
          <SectionCard icon={_getImage(icons)("bar-chart")}>
            <CardTitle>Focused on business effectivity</CardTitle>
            <CardText>
              Our goal is to efficiently and quickly deliver right technology
              solutions for your business. We guarantee that your apps will be
              created to quickly adapt to market conditions.
            </CardText>
          </SectionCard>
        </SectionItems>
        <ContactButton />
      </Strenghts>
      {data.caseStudies.nodes.length > 0 && (
        <Projects id="projects">
          <SectionTitle>projects</SectionTitle>
          <SectionSubtitle>our selected works</SectionSubtitle>

          {data.caseStudies.nodes.map(
            (
              {
                title,
                client,
                thumbnail,
                featured,
                description: { description },
                slug,
                category,
                tags,
              },
              index,
            ) => (
              <CaseStudy
                key={slug}
                image={
                  <Img fluid={thumbnail.localFile.childImageSharp.fluid} />
                }
                clientLogo={
                  <Img
                    fluid={client.logo.localFile.childImageSharp.fluid}
                    imgStyle={{ width: "auto" }}
                  />
                }
                featured={index === 0 ? true : featured}
                title={title}
                description={description}
                url={`/case-study/${slug}#content`}
                category={{
                  text: category.text,
                  url: `/case-studies/category/${category.url}`,
                }}
                tags={tags.map(({ id, text, url }) => ({
                  id,
                  text,
                  url: `/case-studies/tag/${url}`,
                }))}
              />
            ),
          )}
          <SeeMoreButton />
        </Projects>
      )}
      {data.teamMembers.edges.length > 0 && (
        <Team id="team">
          <SectionTitle>our team</SectionTitle>
          <SectionSubtitle>we’re responsible for your success</SectionSubtitle>
          <SectionItems>
            {data.teamMembers.edges.map(
              ({
                node: {
                  id,
                  name,
                  position,
                  socialLinks,
                  biography: { biography },
                  photo,
                },
              }) => (
                <TeamMember
                  key={id}
                  photo={<Img fluid={photo.localFile.childImageSharp.fluid} />}
                  name={name}
                  bio={biography}
                  position={position}
                  socialLinks={socialLinks}
                />
              ),
            )}
          </SectionItems>
          <ContactButton />
        </Team>
      )}
      <OurTeam
        id={data.teamMembers.edges.length > 0 ? undefined : "team"}
        title="about Cometari"
        subtitle="we're responsible for your success"
        text="He knows IT like the back of his hand – he's been in this game for nearly a quarter-century. He began as a humble system administrator. Then he was a team leader and a project manager where he successfully carried out bold visions of the company. Technically speaking, Jakub coordinates projects in the area of infrastructure, containers and tools supporting this technology."
        image={
          <KubaWrapper>
            <KubaImage fluid={data.kubaPhotoBig.childImageSharp.fluid} />
            <KubaBio
              position={data.kuba.position}
              name={data.kuba.name}
              socialLinks={data.kuba.socialLinks}
            />
          </KubaWrapper>
        }
      />
      <Services>
        <ServicesBackground fluid={data.services.childImageSharp.fluid} />
        <SectionTitle>DevOps Culture</SectionTitle>
        <SectionSubtitle>Check how we work</SectionSubtitle>
        <SectionItems>
          <SectionCard icon={_getImage(icons)("project-management")}>
            <CardTitle>Kick-off workshops</CardTitle>
            <CardText>
              We start each project with a kick-off workshop to learn about the
              client's expectations, needs and requirements. At this workshop,
              we set a goal that we then consistently implement at the stage of
              project implementation.
            </CardText>
          </SectionCard>
          <SectionCard icon={_getImage(icons)("workshop")}>
            <CardTitle>Weekly call</CardTitle>
            <CardText>
              Communication is core. Thanks to weekly calls, the client is kept
              updated about the status of the project. We organize weekly
              conversations with clients, live or online, in order to validate
              work progress.
            </CardText>
          </SectionCard>
          <SectionCard icon={_getImage(icons)("help")}>
            <CardTitle>Demo Visualisation</CardTitle>
            <CardText>
              We transform lines of code into specific products and solutions,
              which we then present to the client as a demo. We provide real
              value at every stage of project implementation.
            </CardText>
          </SectionCard>
        </SectionItems>
      </Services>
      <Newsletter>
        <SectionTitle>newsletter</SectionTitle>
        <SectionSubtitle>Let’s stay in touch</SectionSubtitle>
        <NewsletterForm />
      </Newsletter>
    </Layout>
  )
}

const _Subtitle = styled.p`
  font-weight: 500;
  line-height: 1.3;
  margin-top: ${rem("10px")};

  ${fluidRange(
    {
      prop: "fontSize",
      fromSize: "28px",
      toSize: "42px",
    },
    "400px",
    "1000px",
  )}
`
const _SectionTransparent = styled(Card).attrs({
  as: "section",
  shadowColor: "transparent",
})`
  display: flex;
  padding: 0 ${rem("24px")};
  grid-column: full;
  justify-content: center;
  flex-wrap: wrap;
`

const SectionTitle = styled(Badge).attrs({ as: "h2" })`
  grid-column: main;
  grid-row: 1;
  justify-self: center;
`
const SectionSubtitle = styled(_Subtitle)`
  width: 100%;
  grid-column: main;
  grid-row: 2;
  justify-self: center;
  text-align: center;
`
const SectionCard = styled(IconCard).attrs({ color: "#fff" })`
  color: ${props => props.theme.color.text};
`
const TeamMember = styled(_TeamMember)``
const IconList = styled(_IconList)`
  align-self: flex-start;

  @media screen and (min-width: ${rem("1200px")}) {
    flex-basis: 33.3%;
    min-width: 33.3%;
  }

  ${List} {
    @media screen and (max-width: ${rem("1260px")}) {
      column-count: 2;
      column-gap: ${rem("38px")};
      grid-column: 1/3;
    }
  }
`
const SectionItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  /* Replace margins with this after Chrome 84 */
  /* gap: ${rem("73px")}; */

  ${SectionCard},
  ${TeamMember},
  ${IconList} {
    max-width: ${rem("300px")};

    /* Remove these margins after Chrome 84 widespread */
    ${fluidRange(
      {
        prop: "marginLeft",
        fromSize: "0px",
        toSize: "36px",
      },
      "400px",
      "1000px",
    )}
    ${fluidRange(
      {
        prop: "marginRight",
        fromSize: "0px",
        toSize: "36px",
      },
      "400px",
      "1000px",
    )}

    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "12px",
        toSize: "0px",
      },
      "400px",
      "1000px",
    )}
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "12px",
        toSize: "0px",
      },
      "400px",
      "1000px",
    )}
  }

  ${IconList} {
    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: "50px",
        toSize: "0px",
      },
      "400px",
      "1000px",
    )}
    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: "12px",
        toSize: "0px",
      },
      "400px",
      "1000px",
    )}
  }
`
const CardTitle = styled.h3`
  font-size: ${rem("24px")};
  font-weight: 500;
  letter-spacing: ${rem("-0.39px")};
`
const CardText = styled.p`
  font-size: ${rem("16px")};
  font-weight: 300;
  line-height: 1.5;
`

const Clients = styled(_Clients)`
  grid-column: full;
  max-width: 100%;
  overflow: unset; /* This prevents bug in Firefox with CSS Grid */

  @media screen and (max-width: ${rem("1260px")}) {
    border-radius: 0;
  }

  @media screen and (min-width: ${rem("1260px")}) {
    grid-column: 3 / span 10;
  }
`
const Newsletter = styled.section`
  grid-column: main;
  text-align: center;

  @media screen and (min-width: ${rem("1260px")}) {
    grid-column: 4 / span 8;
  }
`
const Specializations = styled(Card).attrs({
  as: "section",
  shadowColor: "transparent",
})`
  display: flex;
  padding: 0 ${rem("24px")};
  grid-column: main;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${props => props.theme.color.bgDark};
  color: ${props => props.theme.color.textLight};
  position: relative;
  text-align: center;

  ${fluidRange(
    {
      prop: "paddingBottom",
      fromSize: "15px",
      toSize: "128px",
    },
    "400px",
    "1000px",
  )}
  ${fluidRange(
    {
      prop: "paddingTop",
      fromSize: "64px",
      toSize: "128px",
    },
    "400px",
    "1000px",
  )}

  @media screen and (min-width: ${rem("1200px")}) {
    text-align: left;

    &::before {
      content: "";
      background-image: ${`url(${footerImg})`};
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      top: 5%;
      left: 0;
      bottom: 5%;
      right: 0;
      z-index: 0;
    }
  }
`
const Strenghts = styled(_SectionTransparent)``
const Projects = styled(Card).attrs({
  as: "section",
  shadowColor: "transparent",
})`
  display: flex;
  padding: 0 ${rem("24px")};
  justify-content: center;
  flex-wrap: wrap;
  grid-column: main;
  background-color: #f9fafb;

  ${fluidRange(
    {
      prop: "paddingBottom",
      fromSize: "64px",
      toSize: "128px",
    },
    "400px",
    "1000px",
  )}
  ${fluidRange(
    {
      prop: "paddingTop",
      fromSize: "64px",
      toSize: "128px",
    },
    "400px",
    "1000px",
  )}
`
const SeeMoreButton = styled(_Button).attrs({
  children: "see more",
  as: Link,
  to: "/case-studies#content",
})`
  width: ${rem("238px")};
`
const CaseStudy = styled(_CaseStudy)`
  margin: 20px 0;
`
const Team = styled(_SectionTransparent)``
const ServicesBackground = styled(BackgroundImage)``
const Services = styled(_SectionTransparent)`
  grid-column: main;
  background-color: ${props => props.theme.color.primary};
  color: #fff;
  position: relative;
  z-index: 0;
  overflow: hidden;

  ${fluidRange(
    {
      prop: "paddingBottom",
      fromSize: "66px",
      toSize: "128px",
    },
    "400px",
    "1000px",
  )}
  ${fluidRange(
    {
      prop: "paddingTop",
      fromSize: "66px",
      toSize: "128px",
    },
    "400px",
    "1000px",
  )}

  @media screen and (min-width: ${rem("1200px")}) {
    margin-top: ${rem("-67px")};
  }

  ${ServicesBackground} {
    z-index: -1;
    clip-path: circle(50% at top);
    transform: scale(1.1);
    transform-origin: top;
    position: absolute !important;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    &,
    &::before,
    &::after {
      background-color: ${props => props.theme.color.primary};
      background-blend-mode: multiply;
    }

    &::before {
      background-size: auto !important;
    }

    &::before,
    &::after {
      background-position: top center !important;
    }
  }
`
const ContactButton = styled(_ContactButton)`
  margin-top: ${rem("64px")};
`
const Hero = styled(_Hero)`
  grid-column: full;
  margin-top: ${rem("20px")};

  @media screen and (max-width: ${rem("1199px")}) {
    margin-top: ${rem("100px")};
  }
`
const OurTeam = styled(_Hero)`
  grid-column: full;

  ${ImageWrapper} {
    grid-column: 8 / span 5;
  }
`
const KubaImage = styled(Img).attrs({ alt: "Jakub Hajek" })`
  user-select: none;
  z-index: -1;
`
const KubaBio = styled(TeamMemberCircle)`
  background: transparent;
`
const KubaWrapper = styled.div`
  position: relative;

  ${KubaBio} {
    position: absolute;
    right: -25%;
    top: 45%;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 50%;
  }

  &::before {
    height: 126px;
    width: 126px;
    left: 35%;
    top: 13%;
    background-color: ${props => props.theme.color.primary};
  }

  &::after {
    height: 355px;
    width: 355px;
    right: -25%;
    top: 45%;
    background-color: ${props =>
      transparentize(0.1, props.theme.color.primary)};
    mix-blend-mode: multiply;
  }
`
const Layout = styled(_Layout)`
  background-image: ${props => `url(${props.backgroundImage})`};
  background-repeat: no-repeat;
  background-position: 0 30px;

  ${Main} {
    background-image: ${props => `url(${props.backgroundImage})`};
    background-repeat: repeat-x;
    background-position: bottom -200px left 0;
  }

  ${Strenghts},
  ${Team},
  ${Newsletter} {
    margin-bottom: ${rem("64px")};
    margin-top: ${rem("64px")};
  }

  ${Newsletter} {
    margin-bottom: ${rem("128px")};
  }
`

const _findByName = arr => name => {
  const found = arr.filter(({ node }) => node.name === name)

  if (found.length < 1) {
    throw new Error(`Resource named "${name}" was not found`)
  } else if (found.length !== 1) {
    throw new Error(`More than one resource named "${name}" was found`)
  }

  return found.map(({ node: { ...props } }) => props)[0]
}

const _getImage = fn => (name, customName) => (
  <img src={fn(name).publicURL} alt={customName ?? name} />
)

export const query = graphql`
  query {
    teamMembers: allContentfulAuthor(
      filter: { teamMember: { eq: true }, featured: { eq: true } }
    ) {
      edges {
        node {
          id
          name
          position
          biography {
            biography
          }
          photo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 150, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          socialLinks: childrenContentfulAuthorSocialLinkJsonNode {
            id
            type
            url
          }
        }
      }
    }
    kuba: contentfulAuthor(name: { regex: "/jakub hajek/i" }) {
      id
      name
      position
      socialLinks: childrenContentfulAuthorSocialLinkJsonNode {
        id
        type
        url
      }
    }
    kubaPhotoBig: file(
      absolutePath: { regex: "/illustrations/jakub-hajek.png$/" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    caseStudies: allContentfulCaseStudy(
      limit: 4
      sort: { fields: [createdAt, featured], order: [DESC, DESC] }
    ) {
      nodes {
        title
        category {
          text
          url
        }
        tags {
          id
          text
          url
        }
        description {
          description
        }
        featured
        slug
        client {
          logo {
            localFile {
              childImageSharp {
                fluid(maxHeight: 30) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
        thumbnail {
          localFile {
            childImageSharp {
              fluid(maxWidth: 461, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    services: file(absolutePath: { regex: "/backgrounds/services.png$/" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    icons: allFile(
      filter: {
        absolutePath: {
          regex: "/icons/(project-management|workshop|help|knowledge|destination|bar-chart).svg$/"
        }
      }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    iconsLight: allFile(
      filter: {
        absolutePath: {
          regex: "/icons/light/(analysis|infinity|cloud-computing|airplane).svg$/"
        }
      }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    backgroundsVector: allFile(
      filter: { absolutePath: { regex: "/backgrounds/(page|footer).svg$/" } }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    illustrationsVector: allFile(
      filter: {
        absolutePath: { regex: "/illustrations/devops-special-team.svg$/" }
      }
    ) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`
