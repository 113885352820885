import styled from "styled-components"
import { rem } from "polished"
import { IoIosArrowForward } from "react-icons/io"

import _Link from "./link"

export const Link = styled(_Link)`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.color.link};
`
export const ArrowRight = styled(IoIosArrowForward)`
  height: ${rem("12px")};
`
export const Icon = styled.div`
  display: inline-flex;
  align-items: center;
`
