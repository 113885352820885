import React from "react"
import PropTypes from "prop-types"

import { Wrapper, Icon, Link, Arrow, Tags } from "./category.style"
import infinityIcon from "../../images/icons/infinity.svg"

Category.propTypes = {
  category: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
}

export default function Category({ category, tags, ...props }) {
  return (
    <Wrapper {...props}>
      <Icon src={infinityIcon} role="presentation" />
      <Link to={category.url}>{category.text}</Link>
      {tags?.length > 0 && (
        <>
          <Arrow />
          <Tags tags={tags} />
        </>
      )}
    </Wrapper>
  )
}
