import styled, { css } from "styled-components"
import { rem } from "polished"

import _Category from "../category/category"
import _ShowMore from "../links/show-more"
import { images } from "../../utils/styles"

export const ClientLogo = styled.div`
  ${images(css`
    max-width: 100%;
    max-height: ${rem("30px")};
  `)}
`
export const Header = styled.header`
  padding-bottom: ${rem("24px")};

  ${ClientLogo} + & {
    padding-top: ${rem("24px")};
  }
`
export const Title = styled.h3`
  margin: 0;
  font-size: ${rem("24px")};
  font-weight: 500;
  letter-spacing: ${rem("-0.39px")};
  padding-bottom: ${rem("13px")};
`
export const Category = styled(_Category)`
  font-size: ${rem("16px")};
  display: flex;
  align-items: center;
`
export const Description = styled.div`
  font-weight: 300;
  line-height: 1.5;
`
export const Footer = styled.footer``
export const ShowMore = styled(_ShowMore)`
  line-height: 3.41;
`
