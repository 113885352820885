import styled from "styled-components"
import { rem, transparentize } from "polished"

import _Card from "./card"
import _Badge from "../badge"
import _SocialLinks, { ListItem } from "../links/social"
import { circle } from "../../utils/styles"

export const Wrapper = styled.article`
  ${circle}

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => rem(`${props.size}px`)};
  width: ${props => rem(`${props.size}px`)};
  position: relative;
  background-color: ${props =>
    props.color ?? transparentize(0.1, props.theme.color.primary)};
`
export const Card = styled(_Card)`
  width: 60%;
  margin-left: -11%;
  margin-top: ${props => props.hasLinks && "10%"};
  padding: 5% 5% 0;
`
export const Badge = styled(_Badge)`
  padding-left: ${rem("10px")};
  padding-right: ${rem("10px")};
`
export const Name = styled.p`
  margin-top: ${rem("12px")};
  font-size: ${rem("24px")};
  font-weight: 700;
`
export const Footer = styled.footer`
  width: 50%;
  padding-top: ${rem("13px")};
  text-align: right;
`
export const SocialLinks = styled(_SocialLinks)`
  ${ListItem} + ${ListItem} {
    margin-left: ${rem("12px")};
  }
`
