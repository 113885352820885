import React from "react"
import PropTypes from "prop-types"

import { List, ListItem, Link } from "./tags.style"

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
}

export default function Tags({ tags }) {
  if (typeof tags.length !== "number" || tags.length < 1) {
    return null
  }

  const listItems = tags.map(tag => (
    <ListItem key={tag.id ?? tag.url}>
      <Link to={tag.url}>{tag.text}</Link>
    </ListItem>
  ))

  return <List>{listItems}</List>
}

export { List, ListItem }
