import React from "react"
import PropTypes from "prop-types"

import ImgCard, { Size, Link } from "./img-card"
import {
  ClientLogo,
  Header,
  Title,
  Category,
  Description,
  Footer,
  ShowMore,
} from "./case-study.style"

CaseStudy.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  category: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  clientLogo: PropTypes.node,
  size: PropTypes.oneOf(Object.values(Size)),
  featured: PropTypes.bool,
  titleProps: PropTypes.object,
}

export default function CaseStudy({
  title,
  description,
  image,
  clientLogo,
  url,
  size,
  featured,
  category,
  tags,
  titleProps,
  ...props
}) {
  return (
    <ImgCard {...props} {...{ image, size, featured, url }}>
      {breakpoint => (
        <>
          {clientLogo && <ClientLogo>{clientLogo}</ClientLogo>}
          <Header>
            <Title {...titleProps}>
              <Link to={url}>{title}</Link>
            </Title>
            <Category
              category={category}
              tags={breakpoint !== Size.SMALL ? tags : []}
            />
          </Header>
          <Description>
            <Link to={url}>{description}</Link>
          </Description>
          <Footer>
            <ShowMore to={url} />
          </Footer>
        </>
      )}
    </ImgCard>
  )
}

export { Size }
export * from "./case-study.style"
