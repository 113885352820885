import React from "react"
import PropTypes from "prop-types"

import { Link, Icon, ArrowRight } from "./show-more.style"

ShowMore.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default function ShowMore({ children = "Show more", ...props }) {
  return (
    <Link {...props}>
      {children}&nbsp;
      <Icon>
        <ArrowRight />
      </Icon>
    </Link>
  )
}

export * from "./show-more.style"
